<template>
  <b-container>
    <b-card>
      <b-row>
        <b-col>
          <p class="font-weight-bolder">Informasi Detail</p>
        </b-col>
      </b-row>
      <b-form v-on:submit.prevent>
        <b-row>
          <b-col md="12">
            <b-form-group label="Nama" label-for="name">
              <b-form-input id="name" placeholder="Nama" v-model="form.name" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Deskripsi" label-for="description">
              <b-form-textarea
                id="description"
                placeholder="Deskripsi"
                v-model="form.description"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col md="12">
            <b-form-group label="Alamat" label-for="address">
              <b-form-textarea
                id="address"
                placeholder="Alamat"
                v-model="address.address"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Kelurahan" label-for="village">
              <b-form-input
                id="village"
                placeholder="Kelurahan"
                v-model="address.village"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Kecamatan" label-for="district">
              <b-form-input
                id="district"
                placeholder="Kecamatan"
                v-model="address.district"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Kota" label-for="city">
              <b-form-input
                id="city"
                placeholder="Kota"
                v-model="address.city"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Provinsi" label-for="province">
              <b-form-input
                id="province"
                placeholder="Provinsi"
                v-model="address.province"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Negara" label-for="country">
              <b-form-input
                id="country"
                placeholder="Negara"
                v-model="address.country"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Kode Pos" label-for="postal_code">
              <b-form-input
                id="postal_code"
                placeholder="Kode Pos"
                v-model="address.postal_code"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card>
      <b-row class="mb-1">
        <b-col md="4">
          <b-form-group label="Latitude" label-for="latitude">
            <b-form-input
              id="latitude"
              placeholder="Latitude"
              v-model="address.latitude"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Longitude" label-for="longitude">
            <b-form-input
              id="longitude"
              placeholder="Longitude"
              v-model="address.longitude"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="Cari"
            variant="primary"
            @click="updateLocation()"
            class="mt-2"
          >
            cari
          </b-button>
        </b-col>
      </b-row>
      <GmapMap :center="center" :zoom="17" style="width: 100%; height: 400px">
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="true"
          @click="center = m.position"
          @dragend="updateCoordinates"
        />
      </GmapMap>
    </b-card>
    <b-row>
      <!-- submit and reset -->
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="success"
          @click="submit()"
          block
        >
          Simpan
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BFormTextarea,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BBadge,
  BContainer,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ToastificationContent,
    BFormTextarea,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
    BBadge,
    BContainer,
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.geolocate();
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
      },
      address: {
        address: "",
        village: "",
        district: "",
        city: "",
        province: "",
        country: "",
        postal_code: "",
        latitude: null,
        longitude: null,
      },
      center: { lat: -6.1751150688690535, lng: 106.8271742576702 },
      currentPlace: null,
      markers: [],
      places: [],
      permissions: [],
      errors: "",
      errMessage: "",
      coordinates: {
        lat: null,
        lng: null,
      },
    };
  },
  created() {
    // this.getAllPermissions();
    // this.getDetails();
  },
  methods: {
    submit() {
      if (this.form.name === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "BellIcon",
            text: "Nama tidak boleh kosong",
            variant: "warning",
          },
        });
      } else {
        const id = this.$route.params.id;
        this.$http
          .post("place/charging-station/add", {
            name: this.form.name,
            description: this.form.description,
            address: this.address.address,
            village: this.address.village,
            district: this.address.district,
            city: this.address.city,
            province: this.address.province,
            country: this.address.country,
            postal_code: this.address.postal_code,
            latitude: this.address.latitude,
            longitude: this.address.longitude,
          })
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notifikasi",
                icon: "BellIcon",
                text: "Data berhasil disimpan",
                variant: "success",
              },
            });
            location.href = "/place/charging-stations";
            // console.log(response.data.data)
          })
          .catch((errors) => {
            this.errMessage = errors.response.data.message;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notifikasi",
                icon: "BellIcon",
                text: this.errMessage,
                variant: "warning",
              },
            });
            console.log(errors.response);
          });
      }
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.markers.push({
          position: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        });
        this.address.latitude = position.coords.latitude;
        this.address.longitude = position.coords.longitude;
      });
    },
    updateCoordinates(location) {
      this.address.latitude = location.latLng.lat();
      this.address.longitude = location.latLng.lng();
    },
    updateLocation() {
      this.markers = [];
      this.center = {
        lat: parseFloat(this.address.latitude),
        lng: parseFloat(this.address.longitude),
      };
      this.markers.push({
        position: {
          lat: parseFloat(this.address.latitude),
          lng: parseFloat(this.address.longitude),
        },
      });
    },
  },
};
</script>

<style>
#customercategory option:disabled {
  font-weight: bolder;
  /* color: darkgray; */
  /* text-align: center; */
  background-color: #ebebeb;
}
#customercategory option:not([disabled]) {
  margin-left: 40px !important;
  transform: scaleX(200px);
}
</style>